#preview_images img,
#preview_images_sub img {
  width: 30%;
  display: block;
  margin-bottom: 1rem;
}

.swiper-button-next {
  background-color: #fff;
  border-radius: 0%;
  right: 0;
}

.swiper-button-prev {
  background-color: #fff;
  border-radius: 0%;
  left: 0;
}

.step .step-icon-no-border::after {
  border-left: 0;
}